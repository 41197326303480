html,
body {
  margin: 0;
  /*font-family: 'Roboto', sans-serif !important;*/
  // background-color: var(--body-bg-color, "white");
  // color: var(--body-font-color, "black");
  font-size: 14px;
}

@media print {
  html, body {
    background-color: white;
    color: black;
  }
}


/** LINKS */

a:link {
  color: var(--link-color);
}

a:visited {
  color: var(--link-visited-color);
}

a:hover {
  color: var(--link-hover-color);
}

a:active {
  color: var(--link-active-color);
}

/** UTILITIES */

.autoscroll {
  overflow: auto;
}

.full-page-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.full-page {
  height: calc(100vh - 120px);
  display: flex;
}

.full-page-content.fit-content {
  min-width: max-content;
}

.zoom-anim {
  transition: all .3s;
}

.form-group {
  margin: 15px 0;
}

/* Custom CSS for SF's diagram connector annotations */
/*TODO essayer :root pour inclure ce css seulement dans diagram-detail */
.foreign-object > div > div > .connector-annotation {
  width: fit-content;
  white-space: nowrap;
  transform: translate(-50%, 35px);
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px;
  border-radius: 4px;
  color: var(--body-font-color);
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-size: unset;
}

@import "styles/syncfusion-imports";
@import "styles/global";
@import "styles/print";
@import "styles/vars";
@import "styles/overrides";

@tailwind base;
@tailwind components;
@tailwind utilities;


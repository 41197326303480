@media print {

  /* Hide elements */
  tlgpro-layout app-header,
  tlgpro-layout app-sidebar {
    display: none !important;
  }

  /* Remove scroll */
  tlgpro-layout .app-body {
    margin-top: 0 !important;
    height: auto !important;
    overflow: visible !important;
  }

  tlgpro-layout main {
    display: block !important;
    margin-left: 0 !important;
  }

}

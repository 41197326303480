/* Application vars */
:root {
  /* Surcharge des variables de @tlgpro/layout */
  --header-height: 45px !important;
  --sidebar-width: 220px !important;
  --sidebar-tlgpro-btn-height: 25px !important;
  /* Fin surcharge */
  --app-name-font-size: 17px;
  --app-logo-size: 25px;

  --success-color: var(--md-green-500);
  --error-color: var(--md-red-500);

  --accent-color: #00b0ff;

  --body-bg-color: #2f353a;
  --body-font-color: rgba(255, 255, 255, 0.7);

  --link-color: var(--md-blue-400);
  --link-visited-color: var(--link-color);
  --link-hover-color: var(--md-blue-700);
  --link-active-color: var(--link-hover-color);

  --scrollbar-color: var(--md-grey-400);
  --scrollbar-bg-color: var(--md-grey-800);

  --header-bg-color: #2376ae;
  --header-text-color: rgba(255, 255, 255, 0.8);
  --header-text-hover-color: white;

  --footer-bg-color: #2376ae;
  --footer-bg-disconnected: #d74113;
  --footer-text-color: white;

  --sub-menu-background: var(--md-grey-900);
  --sub-menu-border: var(--md-grey-700);
  --sub-menu-active: var(--header-bg-color);
  --sub-menu-hover: var(--md-grey-800);
  --sub-menu-text-color-action: white;

  --text-active-color: #2376ae;

}

@use "sass:meta";

@import "ej2-icons/styles/material";

.light {
  @include meta.load-css("ej2-angular-base/styles/material.scss");
  @include meta.load-css("ej2-angular-buttons/styles/material.scss");
  @include meta.load-css("ej2-angular-calendars/styles/material.scss");
  @include meta.load-css("ej2-angular-dropdowns/styles/material.scss");
  @include meta.load-css("ej2-angular-inputs/styles/material.scss");
  @include meta.load-css("ej2-angular-navigations/styles/material.scss");
  @include meta.load-css("ej2-angular-popups/styles/material.scss");
  @include meta.load-css("ej2-angular-splitbuttons/styles/material.scss");
  @include meta.load-css("ej2-angular-notifications/styles/material.scss");
  @include meta.load-css("ej2-angular-grids/styles/material.scss");
  @include meta.load-css("ej2-angular-treegrid/styles/material.scss");
  @include meta.load-css("ej2-angular-layouts/styles/material.scss");
}

.dark {
  @include meta.load-css("ej2-angular-base/styles/material-dark.scss");
  @include meta.load-css("ej2-angular-buttons/styles/material-dark.scss");
  @include meta.load-css("ej2-angular-calendars/styles/material-dark.scss");
  @include meta.load-css("ej2-angular-dropdowns/styles/material-dark.scss");
  @include meta.load-css("ej2-angular-inputs/styles/material-dark.scss");
  @include meta.load-css("ej2-angular-navigations/styles/material-dark.scss");
  @include meta.load-css("ej2-angular-popups/styles/material-dark.scss");
  @include meta.load-css("ej2-angular-splitbuttons/styles/material-dark.scss");
  @include meta.load-css("ej2-angular-notifications/styles/material-dark.scss");
  @include meta.load-css("ej2-angular-grids/styles/material-dark.scss");
  @include meta.load-css("ej2-angular-treegrid/styles/material-dark.scss");
  @include meta.load-css("ej2-angular-layouts/styles/material-dark.scss");
}

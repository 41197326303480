ejs-treegrid.hide-header thead {
  display: none;
}

/* GRID */
tlgpro-grid-syncfusion .e-grid .e-rowcell:first-child,
tlgpro-grid-syncfusion .e-grid .e-summarycell:first-child {
  padding-left: 5px;
}

tlgpro-grid-syncfusion .e-grid .e-rowcell {
  padding: 5px;
}


/* Style dropdown grid */
.err-message, .info {
  color: var(--md-grey-400);
}

/*
* Overrides z-index elements syncfusion
*/
/* Menu tri colonne grid, Tooltip, Date Range Picker */
.e-contextmenu-wrapper.e-grid-menu.e-lib,
.e-tooltip-wrap.e-popup.e-lib.e-control.e-popup-open,
.e-daterangepicker.e-popup {
  z-index: 2000 !important;
}

/* Désactive le style du contenu d'un ejs-tooltip contenu dans un card-body */
.card-body ejs-tooltip {
  all: unset;
}

/* Reduction espace perdu dans entête col grid dashboard */
.dashboard-grid ejs-grid .e-headercell, ejs-grid .e-headercell > .e-headercelldiv {
  padding: 0 !important;
}

.dashboard-grid ejs-grid .e-headercell > .e-headercelldiv {
  margin-left: 3px !important;
}


/* Style custom checkbox parametrage PFC dialogue */
.checkbox-container > ejs-checkbox > label {
  padding: 3px;
  background-color: white;
  border-radius: 2px;
}

.pfc-config-treegrid .e-treecell {
  font-size: 14px;
  background-color: var(--md-grey-200);
  padding: 3px;
  border-radius: 2px;
  color: var(--md-dark-text-primary);
}

/* Override dialogue pfc config info */
.dialog-pfc-config-info .e-dlg-content {
  padding-bottom: 0;
}

/* Override z-index dialog info config pfc */
.dialog-pfc-config-info.e-popup.e-popup-open {
  z-index: 2000 !important;
}

/* Affiche last-fetch d'une tuile au hover d'un e-panel-container */
.e-panel-container:hover .last-fetch {
  opacity: .7;
}

/* Cache le header d'un panel d'un dashboard */
.hide-header .e-panel-header {
  height: 0 !important;
  padding: 0 !important;
}

.e-dialog .e-grid {
  border: 0 !important;
}

/* Retire le padding du contenu des dialogues de création */
ejs-dialog.creation-dialog > .e-dlg-content {
  padding: 0 !important;
}

/* Améliore les badges bootstrap */
.badge {
  font-weight: normal !important;
  font-size: 15px !important;
}


.slow-spin {
  -webkit-animation: fa-spin 6s infinite linear;
  animation: fa-spin 6s infinite linear;
}

/* Splitter*/
/* Suppression de la bordure du splitter */
.e-splitter.e-splitter-horizontal, .e-splitter.e-splitter-vertical {
  border: none !important;
}

/*Asterisque: champs Required*/
//ejs-dropdownlist > :not(.e-valid-input) > label::after,
.asterisque > div > label::after {
  color: red !important;
  font-weight: bold;
  content: "*";
  margin-left: 3px;
}



